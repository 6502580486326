import React from 'react';
import './App.css';

function App() {
  const [quote, setQuote] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  const submitQuote = async(e: any)=>{
    e.preventDefault();
    setSubmitting(true);
    setMessage("Sparar citat...");
    const data = await fetch(`/api/quote?quote=${quote}`, {method:'POST'});
    const json = await data.json();
    if (json.Phrase){
      setMessage("Citat sparat!");
      setSubmitting(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <form id="form1" className="App-form" onSubmit={e => submitQuote(e)}>
          <div>
            <input 
              type="text" 
              id="name" 
              className="App-input" 
              placeholder="Citat" 
              value={quote} 
              onChange={e=>setQuote(e.target.value)} />
            <button type="submit" className="App-button" disabled={submitting}>Spara</button>
          </div>
        </form>
        <br/>
        <div>{message}</div>
      </header>
    </div>
  );
}

export default App;